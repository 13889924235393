body {
    font-size: 1em;
    color: grey;
    font-weight: bolder;
    padding-top: env(safe-area-inset-top);
}

meta[name="viewport"] {
    viewport-fit:cover;
}

.title {
    color: orangered;
}

#nav a {
    color: gray;
    font-size: 1em;
    text-decoration: none;
}

.scroll img {
    background-color: rgba(128, 128, 128, 0.2);
    width: 5em;
    height: 5em;
    border-radius: 100%;
}

.scrollBtn {
    border-color: transparent;
    background: transparent;
    position: fixed;
    bottom: 8%;
    right: 5%;
    z-index: 50;
    cursor: pointer;
    padding: 0;
}

#nav a:hover {
    color: orangered;
}

.footer a {
    color: white;
}

#Home {
    margin: 0 auto;
    width: 85vw;
}

#profile {
    margin: 0 auto;
    padding: 1em;
    width: 50vw;
    text-align: center;
    display: flex;
    flex-direction: column;
}

#profile img {
    border-radius: 5%;
    width: 70%;
    height: 60%;
}

.navbar-brand a {
    text-decoration: none;
}

.navbar-nav {
    padding: 0 0.3em;
}

.highlight {
    color: orangered;
}

.highlightSkill {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
}

#skills {
    width: 30%;
    margin: 0 auto;
    text-align: center;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
}

.progress {
    width: 100%;
}

#home-bottom {
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0.8rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 10px;
}

#aws-buttons {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media screen and (max-width: 400px) {
    #home-bottom .vr{
        width: 100%;
        min-height: 0.1em;
    }
    #Home {
        width: 100%;
    }

    .progress {
        width: 100%;
    }

    #skills {
        width: 80%;
    }

    #profile img {
        width: 100%;
        height: 100%;
    }

    #profile {
        width: 100%;
    }

    #footer-btn {
        display: flex;
        flex-flow: row;
        margin-top: 1em;
    }

    #home-bottom {
        flex-direction: column;
        align-content: space-evenly;
    }

    #aws-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .navbar-nav a {
        padding: 0.4em 0;
    }
}

#placeholder {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0.3rem 1rem;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
}

#placeholder h3 {
    text-align: center;
}

#aws-buttons button {
    color: orangered;
}

#experience {
    text-align: center;
    margin: 0 auto;
    width: 90vw;
    min-height: 90vh;
}

#experience h1 {
    padding: 1em 0;
}

#experience button {
    color: orangered;
}

.accordion-body {
    text-align: left;
    font-size: 0.8rem;
}

.accordion-body small {
    color: gray;
}

.nav-tabs {
    --bs-nav-tabs-link-active-bg: rgba(255, 255, 255, 0.05);
}

.accordion {
    --bs-accordion-active-color: gray;
    --bs-accordion-btn-focus-border-color: gray;
    --bs-accordion-btn-focus-box-shadow: orangered;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.footer {
    padding: 0.8em;
    font-size: 1em;
    color: white;
    width: 100%;
    text-align: center;
}

#email {
    color: white;
    text-decoration: none;
}